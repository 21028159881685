import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { APIService } from '../../services/app-service';
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['../header/header.component.css','./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  isCollapsed = true;
  bookData:any;
  constructor(
    private router: Router,
    private  apiService:  APIService
  ) { }

  ngOnInit() {
    this.getBookList();
  }

  logout(){
    localStorage.removeItem('admin');
    this.router.navigate(['/login']);
  }

  getBookList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'book');
      this.bookData = filterList[0];
    });
  }

}
