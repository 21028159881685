import { Component, OnInit } from '@angular/core';
import { APIService } from "../../services/app-service";
import { ApiPath } from "../../../constant";
@Component({
  selector: 'app-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.css']
})
export class DisclosureComponent implements OnInit {
  newFilePath = ApiPath.newsFileUrl;
  resultList:any;
  docList: any;
  constructor(
    private apiService: APIService) { }

  ngOnInit() {
    this.getResultList();
    this.getDocList();
  }
  getDocList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      filterList = list.filter(
        (filterData) => filterData.Designation === "DocumentsInfo"
      );
      console.log(filterList)
      this.docList = filterList;
    });
  }
  getResultList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      filterList = list.filter(
        (filterData) => filterData.Designation === "resultAcademics"
      );
      console.log(filterList)
      this.resultList = filterList;
    });
  }
}
