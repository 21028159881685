import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  bookData: any;
  constructor( private  apiService:  APIService) {
   }

  ngOnInit() {
    this.getBookList();
  }

  getBookList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'book');
      this.bookData = filterList[0];
    });
  }
}
