import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { APIService } from "../../services/app-service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ApiPath } from "../../../constant";
@Component({
  selector: "app-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.css"],
})
export class GeneralComponent implements OnInit {
  @ViewChild('docFileInput') docFileInput;
  @ViewChild('resultFileInput') resultFileInput;
  
  newFilePath = ApiPath.newsFileUrl;
  modalRef: BsModalRef;
  OASISData: any;
  resultList:any;
  docList: any;
  feeForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  htmlContent: any;
  bookTitle: any;
  feeObj = {
    Name: "",
    Address: "",
    SMCId: 0,
    Designation: "fee",
    Priority: 1,
  };
  docObject = {
    Name: "",
    Address: "",
    SMCId: 0,
    Designation: "DocumentsInfo",
    Priority: 1,
  };
  resultObj = {
    Name: "",
    Address: "",
    SMCId: 0,
    Designation: "resultAcademics",
    Priority: 1,
  };
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private apiService: APIService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    if (localStorage.getItem("admin")) {
      this.getOASISData();
      this.getBookList();
      this.getDocList();
    } else {
      this.router.navigate(["/login"]);
    }
  }
  getDocList() {
    this.isLoaderShown = true;
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      this.isLoaderShown = false;
      filterList = list.filter(
        (filterData) => filterData.Designation === "DocumentsInfo"
      );
      console.log(filterList)
      this.docList = filterList;
    },(error)=>{
      console.log(error)
      this.isLoaderShown = false;
    });
  }
  getResultList() {
    this.isLoaderShown = true;
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      this.isLoaderShown = false;
      filterList = list.filter(
        (filterData) => filterData.Designation === "resultAcademics"
      );
      console.log(filterList)
      this.resultList = filterList;
    },(error)=>{
      console.log(error)
      this.isLoaderShown = false;
    });
  }
  getOASISData() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      filterList = list.filter(
        (filterData) => filterData.Designation === "OASIS"
      );
      this.OASISData = filterList[0];
    });
  }

  getBookList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe((response) => {
      list = response;
      filterList = list.filter(
        (filterData) => filterData.Designation === "book"
      );
      this.htmlContent = filterList[0].Address;
      this.bookTitle = filterList[0].Name;
    });
  }

  addBooks() {
    this.isLoaderShown = true;
    const data = {
      SMCId: 4,
      Name: this.bookTitle,
      Address: this.htmlContent,
      Designation: "book",
      Priority: 1,
    };
    return this.apiService.manageFee(data).subscribe((resp) => {
      this.isLoaderShown = false;
      this.getBookList();
      this.toastr.success("Book list updated successfully");
      location.reload();
    });
  }
  uploadImageFile(event) {
    const fileList: FileList = event.target.files;
    if (fileList) {
      this.isLoaderShown = true;
      this.apiService.OASISUpload(fileList[0]).subscribe((response) => {
        this.toastr.success("File uploaded successfully");
        const OASISData = {
          SMCId: 3,
          Name: "OASIS Certificate",
          Address: response["Data"],
          Designation: "OASIS",
          Priority: 1,
        };
        return this.apiService.manageFee(this.docObject).subscribe((resp) => {
          this.isLoaderShown = false;
          this.getOASISData();
          this.toastr.success("OASIS Certificate updated Successfully");
        });
      });
    }
  }
  uploadDocImageFile(event) {
    const fileList: FileList = event.target.files;
    if (fileList) {
      this.isLoaderShown = true;
      this.apiService.OASISUpload(fileList[0]).subscribe((response) => {
        this.toastr.success("File uploaded successfully");
        this.docObject.Address  =  response["Data"];
        this.isLoaderShown = false;       
        this.docFileInput.nativeElement.value = '';
      });
    }
  }
  saveDocInfo() {
      this.isLoaderShown = true;
      this.apiService.manageFee(this.docObject).subscribe((resp) => {
        this.isLoaderShown = false;
        this.getDocList();
        this.toastr.success("Document saved Successfully");
      },(error)=>{
        console.log(error)
        this.isLoaderShown = false;
      });
  }
      deleteFile(id, index, name) {
      if (confirm('Are you sure to delete ' + name + ' ?')) {
        this.apiService.deleteFee(id).subscribe((response) => {
          this.toastr.success('Fee deleted successfully');
          this.docList.splice( index, 1 );
        });
      }
    }
    uploadResultImageFile(event) {
      const fileList: FileList = event.target.files;
      if (fileList) {
        this.isLoaderShown = true;
        this.apiService.OASISUpload(fileList[0]).subscribe((response) => {
          this.toastr.success("File uploaded successfully");
          this.resultObj.Address  =  response["Data"];
          this.isLoaderShown = false;       
          this.resultFileInput.nativeElement.value = '';
        });
      }
    }
    saveResultInfo() {
        this.isLoaderShown = true;
        this.apiService.manageFee(this.resultObj).subscribe((resp) => {
          this.isLoaderShown = false;
          this.toastr.success("Document saved Successfully");
          this.getResultList(); 
        },(error)=>{ 
          console.log(error)
          this.isLoaderShown = false;
        });
    }
      deleteresultFile(id, index, name) {
        if (confirm('Are you sure to delete ' + name + ' ?')) {
          this.apiService.deleteFee(id).subscribe((response) => {
            this.toastr.success('Fee deleted successfully');
            this.resultList.splice( index, 1 );
          });
        }
      }
  // openModal(template: TemplateRef<any>, feeDataObj) {
  //   this.submitted = false;
  //   this.isLoaderShown = false;
  //   this.initValidation(feeDataObj);
  //   this.modalRef = this.modalService.show(template, {
  //     ignoreBackdropClick: true
  //   });
  // }

  // initValidation(feeData) {
  //   this.feeForm = this.formBuilder.group({
  //     Name: [feeData.Name, [Validators.required]],
  //     Designation:  [feeData.Designation , [Validators.required]],
  //     Priority:  [feeData.Priority, [Validators.required]],
  //     SMCId:  [feeData.SMCId, [Validators.required]],
  //     Address:  [feeData.Address, [Validators.required]],
  //   });
  // }

  //   deleteFee(id, index, name) {
  //     if (confirm('Are you sure to delete Fee details of ' + name + ' ?')) {
  //       this.apiService.deleteFee(id).subscribe((response) => {
  //         this.toastr.success('Fee deleted successfully');
  //         this.feeArrayList.splice( index, 1 );
  //       });
  //     }
  //   }

  //  onSubmit() {
  //     this.submitted = true;
  //     if (this.feeForm.valid) {
  //       this.isLoaderShown = true;

  //       return this.apiService.manageFee(this.feeForm.value).subscribe(response => {
  //         this.isLoaderShown = false;
  //         this.modalRef.hide();
  //         this.getFeeList();
  //         this.toastr.success(this.feeForm.value.SMCId === 0 ? 'Fee added successfully' : 'Fee updated Successfully'  );
  //       });
  //     }
  //   }
}
