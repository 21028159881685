import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiPath } from '../../../constant';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-events',
  templateUrl: './add-events.component.html',
  styleUrls: ['./add-events.component.css']
})
export class AddEventsComponent implements OnInit {
  modalRef: BsModalRef;
  eventsArrayList: Array<any> = [];
  newsForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  isMethod:any;
  newsObj = {
    'Id': 0,
    'Title': '',
    'Description': '',
    'Image': '',
    'Date': ''
  };
  filePath = ApiPath.newsFileUrl;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  openModal(template: TemplateRef<any>, newsDataObj,method) {
    this.isMethod = method,
    this.submitted = false;
    this.isLoaderShown = false;
    this.initValidation(newsDataObj);
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  ngOnInit() {
    if (localStorage.getItem('admin')) {

      this.getNewsList();
    } else {
      this.router.navigate(['/login']);
    }
  }

  deleteNews(id,index,title){
    if(confirm('Are you sure to delete "'+ title +'" news ?')) {
      this.apiService.deleteNews(id).subscribe((response) => {
        this.toastr.success('News deleted successfully');
        this.eventsArrayList.splice( index, 1 );
      });
    }
  }
  getDate(evDate){
    const full_date = new Date(evDate).toString();
    const sliced_date = full_date.split('GMT');
    return sliced_date[0].substring(15, 0);
  }

  uploadImageFile(event) {
    this.isLoaderShown = true;
    const fileList: FileList = event.target.files;
    this.apiService.newsFileUpload(fileList[0]).subscribe((response) => {
      this.toastr.success('File uploaded successfully');
      this.newsForm.controls['Image'].setValue(response['Data']);
      this.isLoaderShown = false;
    });
  }

initValidation(newsData) {
  this.newsForm = this.formBuilder.group({
    Id: [newsData.Id, [Validators.required]],
    Title:  [newsData.Title , [Validators.required]],
    Description:  [newsData.Description , [Validators.required]],
    Image:  [newsData.Image],
    Date:  [newsData.Date, [Validators.required]],
  });
}

  getNewsList() {
    return this.apiService.getNewsList().subscribe(response => {
      this.eventsArrayList = response;
    });
  }

 onSubmit() {
  this.isLoaderShown = true;
    this.submitted = true;
    if(this.isMethod === 'add'){
      this.newsForm.controls['Date'].setValue(new Date());
    } else {
      this.newsForm.controls['Date'].setValue(new Date());
    }
    if (this.newsForm.valid) {
      return this.apiService.manageNews(this.newsForm.value, this.isMethod).subscribe(response => {
        this.isLoaderShown = false;
        this.modalRef.hide();
        this.getNewsList();
        this.toastr.success(this.newsForm.value.Id === 0 ? 'News added successfully' : 'News updated Successfully'  );
      });
     }
  }
}
