import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['../about/about.component.css','./management.component.css']
})
export class ManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
