import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { AcademicsComponent } from './components/academics/academics.component';
import { ContactComponent } from './components/contact/contact.component';
import { AdmissionComponent } from './components/admission/admission.component';
import { ManagementComponent } from './components/management/management.component';
import { CBSEComponent } from './components/cbse/cbse.component';
import { ClubsComponent } from './components/clubs/clubs.component';
import { SportsComponent } from './components/sports/sports.component';
import { PTAComponent } from './components/pta/pta.component';
import { CampusComponent } from './components/campus/campus.component';
import { LibraryComponent } from './components/library/library.component';
import { LaboratoryComponent } from './components/laboratory/laboratory.component';
import { ODASComponent } from './components/odas/odas.component';
import { AdmissionGuidelinesComponent } from './components/admission-guidelines/admission-guidelines.component';
import { OpportunitiesComponent } from './components/opportunities/opportunities.component';
import { AnnualReportComponent } from './components/annual-report/annual-report.component';
import { OASISComponent } from './components/oasis/oasis.component';
import { TCComponent } from './components/tc/tc.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AddEventsComponent } from './components/add-events/add-events.component';
import { LoginComponent } from './components/login/login.component';
import { AddTcComponent } from './components/add-tc/add-tc.component';
import { AddFeeSalaryComponent } from './components/add-fee-salary/add-fee-salary.component';
import { AddStaffComponent } from './components/add-staff/add-staff.component';
import { AddSalaryComponent } from './components/add-salary/add-salary.component';
import { ViewAllNewsComponent } from './components/view-all-news/view-all-news.component';
import { AddGalleryComponent } from './components/add-gallery/add-gallery.component';
import { GeneralComponent } from './components/general/general.component';
import { SharedTemplateComponent } from './components/shared-template/shared-template.component';
import { FitIndiaAdminComponent } from './components/fit-india-admin/fit-india-admin.component';
import { FitIndiaComponent } from './components/fit-india/fit-india.component';
import { DisclosureComponent } from './components/disclosure/disclosure.component';
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'about', component: AboutComponent, pathMatch: 'full' },
  { path: 'admissions', component: AdmissionComponent, pathMatch: 'full' },
  { path: 'management', component: ManagementComponent, pathMatch: 'full' },
  { path: 'academics', component: AcademicsComponent, pathMatch: 'full' },
  { path: 'gallery', component: GalleryComponent, pathMatch: 'full' },
  { path: 'campus', component: CampusComponent, pathMatch: 'full' },
  { path: 'admission', component: AdmissionComponent, pathMatch: 'full' },
  { path: 'opportunities', component: OpportunitiesComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactComponent, pathMatch: 'full' },
  { path: 'library', component: LibraryComponent, pathMatch: 'full' },
  { path: 'laboratory', component: LaboratoryComponent, pathMatch: 'full' },
  { path: 'one-day-at-school', component: ODASComponent, pathMatch: 'full' },
  { path: 'admission-guidelines', component: AdmissionGuidelinesComponent, pathMatch: 'full' },
  { path: 'opportunities', component: OpportunitiesComponent, pathMatch: 'full' },
  { path: 'clubs', component: ClubsComponent, pathMatch: 'full' },
  { path: 'sports-and-games', component: SportsComponent, pathMatch: 'full' },
  { path: 'PTA', component: PTAComponent, pathMatch: 'full' },
  { path: 'OASIS', component: OASISComponent, pathMatch: 'full' },
  { path: 'transfer-certificate', component: TCComponent, pathMatch: 'full' },
  { path: 'annual-report', component: AnnualReportComponent, pathMatch: 'full' },
  { path: 'manage-events', component: AddEventsComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'manage-tc', component: AddTcComponent, pathMatch: 'full' },
  { path: 'manage-fee', component: AddFeeSalaryComponent, pathMatch: 'full' },
  { path: 'manage-staff', component: AddStaffComponent, pathMatch: 'full' },
  { path: 'manage-salary', component: AddSalaryComponent, pathMatch: 'full' },
  { path: 'news', component: ViewAllNewsComponent, pathMatch: 'full' },
  { path: 'manage-gallery', component: AddGalleryComponent, pathMatch: 'full' },
  { path: 'general-updates', component: GeneralComponent, pathMatch: 'full' },
  { path: 'general/:id', component: SharedTemplateComponent },
  { path: 'Fit-India-Movement', component: FitIndiaComponent },
  { path: 'manage-mail', component: FitIndiaAdminComponent },
  { path: 'mandatory-disclosure', component: DisclosureComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
