import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { ApiPath } from '../../../constant';
@Component({
  selector: 'app-oasis',
  templateUrl: './oasis.component.html',
  styleUrls: ['./oasis.component.css']
})
export class OASISComponent implements OnInit {
  OASISData: any;
  filePath = ApiPath.newsFileUrl;
  constructor(
    private  apiService:  APIService
  ) { }

  ngOnInit() {
    this.getOASISData();
  }

  getOASISData() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'OASIS');
      this.OASISData = filterList[0];
    });
  }
}
