import { Component, OnInit , TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-add-fee-salary',
  templateUrl: './add-fee-salary.component.html',
  styleUrls: ['./add-fee-salary.component.css']
})
export class AddFeeSalaryComponent implements OnInit {

  modalRef: BsModalRef;
  feeArrayList: any;
  feeForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  feeObj = {
    'Name': '',
    'Address': '',
    'SMCId': 0,
    'Designation': 'fee',
    'Priority': 1
  };
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  openModal(template: TemplateRef<any>, feeDataObj) {
    this.submitted = false;
    this.isLoaderShown = false;
    this.initValidation(feeDataObj);
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  ngOnInit() {
    if (localStorage.getItem('admin')) {
      this.getFeeList();
    } else {
      this.router.navigate(['/login']);
    }
  }


initValidation(feeData) {
  this.feeForm = this.formBuilder.group({
    Name: [feeData.Name, [Validators.required]],
    Designation:  [feeData.Designation , [Validators.required]],
    Priority:  [feeData.Priority, [Validators.required]],
    SMCId:  [feeData.SMCId, [Validators.required]],
    Address:  [feeData.Address, [Validators.required]],
  });
}

  getFeeList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'fee');
      this.feeArrayList = filterList;
    });
  }
  

  deleteFee(id, index, name) {
    if (confirm('Are you sure to delete Fee details of ' + name + ' ?')) {
      this.apiService.deleteFee(id).subscribe((response) => {
        this.toastr.success('Fee deleted successfully');
        this.feeArrayList.splice( index, 1 );
      });
    }
  }

 onSubmit() {
    this.submitted = true;
    if (this.feeForm.valid) {
      this.isLoaderShown = true;

      return this.apiService.manageFee(this.feeForm.value).subscribe(response => {
        this.isLoaderShown = false;
        this.modalRef.hide();
        this.getFeeList();
        this.toastr.success(this.feeForm.value.SMCId === 0 ? 'Fee added successfully' : 'Fee updated Successfully'  );
      });
    }
  }
}
