import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { ApiPath } from '../../../constant';
import { experience,staffs,students} from '../../../assets/files/strength';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  eventsArrayList: Array<any> = [];
  galleryArrayList: any;
  filePath = ApiPath.newsFileUrl;
  galleryPath = ApiPath.galleryFileUrl;
  experience = experience;
  staffs = staffs;
  students = students;
  constructor(
    private  apiService:  APIService
  ) { }

  ngOnInit() {
    this.getNewsList();
    this.getGalleryList();
  }

  getNewsList() {

    return this.apiService.getNewsList().subscribe(response => {
      this.eventsArrayList = response;
    });
  }
  getDate(evDate){
    const full_date = new Date(evDate).toString();
    const sliced_date = full_date.split('GMT');
    return sliced_date[0].substring(15, 0);
  }
  getGalleryList() {
    return this.apiService.getGalleryList().subscribe(response => {
      this.galleryArrayList = response;
    });
  }


}
