import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../services/app-service';
@Component({
  selector: 'app-shared-template',
  templateUrl: './shared-template.component.html',
  styleUrls: ['./shared-template.component.css']
})
export class SharedTemplateComponent implements OnInit {
  sharedData:any;
  constructor(
    private route: ActivatedRoute,
    private  apiService:  APIService) { }

  ngOnInit() {
    console.log(this.route.snapshot.paramMap.get('id'));
    this.getSMCDetailsList(this.route.snapshot.paramMap.get('id'));
  }

  getSMCDetailsList(id) {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === id);
      this.sharedData = filterList[0];
    });
  }
}
