import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-fit-india-admin',
  templateUrl: './fit-india-admin.component.html',
  styleUrls: ['./fit-india-admin.component.css']
})
export class FitIndiaAdminComponent implements OnInit {
  mailArrayList: any;
  constructor(
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    if (localStorage.getItem('admin')) {
      this.getMailList();
    } else {
      this.router.navigate(['/login']);
    }
  }
  getMailList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'mail');
      this.mailArrayList = filterList;
    });
  }
  

  getName(name, type) {
    let res;
    switch (type) {
      case 'Name':
         res = name.split('-');
         return res[0] ? res[0] : 'NA';
          break;
      case 'Email':
        res = name.split('-');
        return res[1] ? res[1] : 'NA';
          break;
      case 'Subject':
        res = name.split('-');
        return res[2] ? res[2] : 'NA';
          break;
      default:
          console.log('No data');
          break;
  }

  }


  deleteMail(id, index) {
    if (confirm('Are you sure to delete this mail ?')) {
      this.apiService.deleteFee(id).subscribe((response) => {
        this.toastr.success('Mail deleted successfully');
        this.mailArrayList.splice( index, 1 );
      });
    }
  }
}
