import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import {
  CarouselModule,
  CollapseModule,
  AccordionModule,
  ModalModule,
  BsDatepickerModule,
} from "ngx-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HomeComponent } from "./components/home/home.component";
import { AboutComponent } from "./components/about/about.component";
import { AcademicsComponent } from "./components/academics/academics.component";
import { ContactComponent } from "./components/contact/contact.component";
import { AdmissionComponent } from "./components/admission/admission.component";
import { ManagementComponent } from "./components/management/management.component";
import { CBSEComponent } from "./components/cbse/cbse.component";
import { ClubsComponent } from "./components/clubs/clubs.component";
import { SportsComponent } from "./components/sports/sports.component";
import { PTAComponent } from "./components/pta/pta.component";
import { CampusComponent } from "./components/campus/campus.component";
import { LibraryComponent } from "./components/library/library.component";
import { LaboratoryComponent } from "./components/laboratory/laboratory.component";
import { ODASComponent } from "./components/odas/odas.component";
import { AdmissionGuidelinesComponent } from "./components/admission-guidelines/admission-guidelines.component";
import { OpportunitiesComponent } from "./components/opportunities/opportunities.component";
import { AnnualReportComponent } from "./components/annual-report/annual-report.component";
import { OASISComponent } from "./components/oasis/oasis.component";
import { TCComponent } from "./components/tc/tc.component";

import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxGalleryModule } from "ngx-gallery";
import { NgxEditorModule } from "ngx-editor";

import { GalleryComponent } from "./components/gallery/gallery.component";
import { AddEventsComponent } from "./components/add-events/add-events.component";
import { FilterPipe } from "./filter";
import { LoginComponent } from "./components/login/login.component";
import { AddTcComponent } from "./components/add-tc/add-tc.component";
import { AddFeeSalaryComponent } from "./components/add-fee-salary/add-fee-salary.component";
import { AddStaffComponent } from "./components/add-staff/add-staff.component";
import { AddSalaryComponent } from "./components/add-salary/add-salary.component";
import { AdminHeaderComponent } from "./components/admin-header/admin-header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ToastrModule } from "ngx-toastr";
import { ViewAllNewsComponent } from "./components/view-all-news/view-all-news.component";
import { AddGalleryComponent } from "./components/add-gallery/add-gallery.component";
import { GeneralComponent } from "./components/general/general.component";
import { SharedTemplateComponent } from "./components/shared-template/shared-template.component";
import { FitIndiaAdminComponent } from "./components/fit-india-admin/fit-india-admin.component";
import { FitIndiaComponent } from "./components/fit-india/fit-india.component";
import { DisclosureComponent } from './components/disclosure/disclosure.component';

@NgModule({
  declarations: [
    FilterPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    AcademicsComponent,
    ContactComponent,
    AdmissionComponent,
    ManagementComponent,
    CBSEComponent,
    ClubsComponent,
    SportsComponent,
    PTAComponent,
    CampusComponent,
    LibraryComponent,
    LaboratoryComponent,
    ODASComponent,
    AdmissionGuidelinesComponent,
    OpportunitiesComponent,
    AnnualReportComponent,
    OASISComponent,
    TCComponent,
    GalleryComponent,
    AddEventsComponent,
    LoginComponent,
    AddTcComponent,
    AddFeeSalaryComponent,
    AddStaffComponent,
    AdminHeaderComponent,
    LoaderComponent,
    AddSalaryComponent,
    ViewAllNewsComponent,
    AddGalleryComponent,
    GeneralComponent,
    SharedTemplateComponent,
    FitIndiaAdminComponent,
    FitIndiaComponent,
    DisclosureComponent,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 9000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    NgxGalleryModule,
    NgxEditorModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
