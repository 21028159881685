import { Component, OnInit } from '@angular/core';
import { ApiPath } from '../../../constant';
import { APIService } from '../../services/app-service';
@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.css']
})
export class TCComponent implements OnInit {
  TCList: Array<any> = [];
  filePath = ApiPath.tcFileUrl;
  term: string;
  tcList: any;
  isInvalidFormat = false;
  constructor(
    private  apiService:  APIService
    ) {
   }

  ngOnInit() {
  }

  getTC(term) {
    if (term.indexOf('/') >= 0) {
      this.isInvalidFormat = false;
        let tcNo: any;
        tcNo  = term.split('/');
        tcNo = tcNo[0] + '-' + tcNo[1];
      return this.apiService.getTC(tcNo).subscribe(response => {
        this.term  = '';
        if (response) {
          this.tcList = response;
        } else {
          this.tcList = 'nodata';
        }

      });
    } else {
      this.isInvalidFormat = true;
    }

  }

}
