import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fit-india',
  templateUrl: './fit-india.component.html',
  styleUrls: ['./fit-india.component.css']
})
export class FitIndiaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
