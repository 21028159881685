import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isLoginFailed = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required]]
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.isLoginFailed = false;
      if ((this.loginForm.value.userName === 'admin') && (this.loginForm.value.password === 'admin')) {
        localStorage.setItem('admin', 'admin');
    this.router.navigate(['/manage-staff']);
      } else {
        this.isLoginFailed = true;
      }
    }
  }
}
