import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiPath } from '../../constant';
@Injectable({
  providedIn: 'root'
})


export class APIService {

  constructor(private http: HttpClient) { }

  baseUrl = 'http://bishopmoorevidyapithcherthala.com/api/';
  // createContact(events){
  //     return  this.httpClient.post(this.baseUrl+'addevents',events);
  // }
  createContact(events): Observable<any> {
    return this.http.post<any>(this.baseUrl, events)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }
  getTCList(): Observable<any> {
    return this.http.get<any>(ApiPath.baseUrl + 'TcDetails')
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  getTC(tc): Observable<any> {
    return this.http.get<any>(ApiPath.baseUrl + 'TcDetails/' + tc)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  manageTC(tcData, type) {
    if (type === 'add') {
      return this.http.post(ApiPath.baseUrl + 'TcDetails', tcData);
    } else {
      return this.http.put(ApiPath.baseUrl + 'TcDetails?id=' + tcData.TcId, tcData);
    }
  }

  deleteTC(id) {
    return this.http.delete(ApiPath.baseUrl + 'TcDetails/' + id);
  }

  tcUpload(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(ApiPath.baseUrl + 'TCFile', formData);
  }

  getNewsList(): Observable<any> {
    return this.http.get<any>(ApiPath.baseUrl + 'Home')
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  manageNews(newsData, type) {
    if (type === 'add') {
      return this.http.post(ApiPath.baseUrl + 'Home', newsData);
    } else {
      return this.http.put(ApiPath.baseUrl + 'Home', newsData);
    }
  }

  newsFileUpload(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(ApiPath.baseUrl + 'NewsFile', formData);
  }

  deleteNews(id) {
    return this.http.delete(ApiPath.baseUrl + 'Home/' + id);
  }

  getGalleryList() {
    return this.http.get(ApiPath.baseUrl + 'ImageGallery');
  }

  getGalleryListByYear(year) {
    return this.http.get(ApiPath.baseUrl + 'ImageGallery?Year=' + year);
  }

  galleryUpload(file, year) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(ApiPath.baseUrl + 'ImageGallery?Year=' + year, formData);
  }

  deleteImage(id) {
    return this.http.delete(ApiPath.baseUrl + 'ImageGallery/' + id);
  }

  getSMCDetailsList() {
    return this.http.get(ApiPath.baseUrl + 'SMCDetails');
  }

  manageFee(data) {
    if (data.SMCId === 0) {
      return this.http.post(ApiPath.baseUrl + 'SMCDetails', data);
    } else {
      return this.http.put(ApiPath.baseUrl + 'SMCDetails', data);
    }
  }

  manageMail(data) {
    if (data.SMCId === 0) {
      return this.http.post(ApiPath.baseUrl + 'SMCDetails', data);
    } else {
      return this.http.put(ApiPath.baseUrl + 'SMCDetails', data);
    }
  }

  deleteFee(id) {
    return this.http.delete(ApiPath.baseUrl + 'SMCDetails/' + id);
  }

  manageSalary(data) {
    if (data.SMCId === 0) {
      return this.http.post(ApiPath.baseUrl + 'SMCDetails', data);
    } else {
      return this.http.put(ApiPath.baseUrl + 'SMCDetails', data);
    }
  }

  deleteSalary(id) {
    return this.http.delete(ApiPath.baseUrl + 'SMCDetails/' + id);
  }

  OASISUpload(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(ApiPath.baseUrl + 'NewsFile', formData);
  }

  getStaffList() {
    return this.http.get(ApiPath.baseUrl + 'TeachingStaff');
  }

  getSubjectList() {
    return this.http.get(ApiPath.baseUrl + 'Subject');
  }

  manageStaff(data) {
    console.log(data);
    let sData: any;
    if (data.SatffId === 0) {
      return this.http.post(ApiPath.baseUrl + 'TeachingStaff', data);
    } else {
      sData = {
        'StaffId': data.SatffId,
        'StaffName': data.StaffName,
        'Designation': data.Designation,
        'DOB': data.DOB,
        'DateOfAppointment': data.DateOfAppointment,
        'Qualification': data.Qualification,
        'Trained': data.Trained,
        'PartTime': data.PartTime,
        'SubjectId': data.SubjectId
      };
      return this.http.put(ApiPath.baseUrl + 'TeachingStaff', sData);
    }
  }
  deleteStaff(id) {
    return this.http.delete(ApiPath.baseUrl + 'TeachingStaff/' + id);
  }
}
