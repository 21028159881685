import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { ApiPath } from '../../../constant';
import { APIService } from '../../services/app-service';
import { galleryYears} from '../../../assets/files/strength';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
    filePath = ApiPath.galleryFileUrl;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: any;
    yearArray = galleryYears;
    year: any = new Date().getFullYear().toString();
  constructor(
    private  apiService:  APIService,
  ) { }

  ngOnInit() {
        this.getGalleryList(this.year);
        this.yearArray.unshift(new Date().getFullYear().toString());
        this.galleryOptions = [
           { 'image': true, 'thumbnailsRemainingCount': false, 'height': '500px' },
          { 'breakpoint': 991, 'width': '100%', 'thumbnailsColumns': 10 }
        ];
  }

  getGalleryList(year) {
      this.year = year;
    return this.apiService.getGalleryListByYear(year).subscribe(response => {
        const arr: any = response;
        let gallObj: any;
        const galleryArray =  [];
        const galleryPath = this.filePath;
        arr.forEach(function (value) {
            gallObj = {
                small: galleryPath + value.ImageName,
                medium: galleryPath + value.ImageName,
                big: galleryPath + value.ImageName
            };
            galleryArray.push(gallObj);
        });
        this.galleryImages = galleryArray;
    });
  }

}
