import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  mailForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  mailObj = {
    'Name': '',
    'Address': '',
    'SMCId': 0,
    'Designation': 'mail',
    'Priority': 1
  };
  constructor(
    private formBuilder: FormBuilder,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router) {

  }

  ngOnInit() {

  var lat: number = 51.678418;
  var lng: number = 7.809007;
  this.initValidation(this.mailObj);
  let filterList: any;
  let list: any = [];
  return this.apiService.getSMCDetailsList().subscribe(response => {
    list = response;
    filterList = list.filter(
      filterData => filterData.Designation === 'mail');
      console.log(filterList)
  });
  }

  initValidation(mailData) {
    this.mailForm = this.formBuilder.group({
      Name: [mailData.Name, []],
      Subject: ['', []],
      EmailAddress: ['', []],
      Designation:  [mailData.Designation , [Validators.required]],
      Priority:  [mailData.Priority, [Validators.required]],
      SMCId:  [mailData.SMCId, [Validators.required]],
      Address:  [mailData.Address, [Validators.required]],
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.mailForm.valid) {
      console.log(this.mailForm.value.Subject)
      const data = {
        'Name': `${this.mailForm.value.Name}-${this.mailForm.value.EmailAddress}-${this.mailForm.value.Subject}`,
        'Address': this.mailForm.value.Address,
        'SMCId': 0,
        'Designation': 'mail',
        'Priority': 1
      };
      this.isLoaderShown = true;
      return this.apiService.manageMail(data).subscribe(response => {
        this.isLoaderShown = false;
        this.toastr.success(this.mailForm.value.SMCId === 0 ? 'Mail send successfully' : 'Mail send successfully'  );
      });
    }
  }
}
