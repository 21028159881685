import { Component, OnInit, TemplateRef } from '@angular/core';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiPath } from '../../../constant';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import { galleryYears} from '../../../assets/files/strength';
@Component({
  selector: 'app-add-gallery',
  templateUrl: './add-gallery.component.html',
  styleUrls: ['./add-gallery.component.css']
})
export class AddGalleryComponent implements OnInit {
  galleryArrayList: any;
  galleryForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  yearArray = galleryYears;
  year: any = new Date().getFullYear().toString();
  filePath = ApiPath.galleryFileUrl;
  constructor(
    private formBuilder: FormBuilder,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}



  ngOnInit() {
    if (localStorage.getItem('admin')) {
      this.yearArray.unshift(new Date().getFullYear().toString());
      this.getGalleryList();
    } else {
      this.router.navigate(['/login']);
    }
  }

  deleteNews(id, index, title) {
    if (confirm('Are you sure to delete "' + title + '" ?')) {
      this.apiService.deleteImage(id).subscribe((response) => {
        this.toastr.success('Image deleted successfully');
        this.galleryArrayList.splice( index, 1 );
      });
    }
  }


  uploadImageFile(event) {
    const fileList: FileList = event.target.files;
    if(fileList) {
    this.isLoaderShown = true;
      this.apiService.galleryUpload(fileList[0], this.year).subscribe((response) => {
        this.toastr.success('File uploaded successfully');
        this.getGalleryList();
      this.isLoaderShown = false;
      });
    }
  }

  getGalleryList() {
    return this.apiService.getGalleryList().subscribe(response => {
      this.galleryArrayList = response;
    });
  }



}
