import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { ApiPath } from '../../../constant';
@Component({
  selector: 'app-view-all-news',
  templateUrl: './view-all-news.component.html',
  styleUrls: ['./view-all-news.component.css']
})
export class ViewAllNewsComponent implements OnInit {
  eventsArrayList: Array<any> = [];
  filePath = ApiPath.newsFileUrl;
  constructor(
    private  apiService:  APIService
  ) { }

  ngOnInit() {
    this.getNewsList();
  }

  getNewsList() {

    return this.apiService.getNewsList().subscribe(response => {
      this.eventsArrayList = response;
    });
  }
  getDate(evDate){
    const full_date = new Date(evDate).toString();
    const sliced_date = full_date.split('GMT');
    return sliced_date[0].substring(15, 0);
  }
}
