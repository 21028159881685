import { Component, OnInit , TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiPath } from '../../../constant';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-add-tc',
  templateUrl: './add-tc.component.html',
  styleUrls: ['./add-tc.component.css']
})
export class AddTcComponent implements OnInit {
  modalRef: BsModalRef;
  tcArrayList: Array<any> = [];
  tcForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  tcObj = {
    'StudentId': '',
    'StudentName': '',
    'TCFile': '',
    'TcId': 0,
    'TcNo': ''
  };
  filePath = ApiPath.tcFileUrl;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  openModal(template: TemplateRef<any>, tcDataObj) {
    this.submitted = false;
    this.isLoaderShown = false;
    this.initValidation(tcDataObj);
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  ngOnInit() {
    if (localStorage.getItem('admin')) {

      this.getTCList();
    } else {
      this.router.navigate(['/login']);
    }
  }

  uploadImageFile(event) {
    this.isLoaderShown = true;
    const fileList: FileList = event.target.files;
    this.apiService.tcUpload(fileList[0]).subscribe((response) => {
      this.isLoaderShown = false;

    this.toastr.success('File uploaded successfully');
      this.tcForm.controls['TCFile'].setValue(response['Data']);

    });
  }

initValidation(tcData) {
  this.tcForm = this.formBuilder.group({
    StudentName: [tcData.StudentName, [Validators.required]],
    StudentId:  [tcData.StudentId , [Validators.required]],
    TcNo:  [tcData.TcId === 0 ? tcData.TcNo : this.formatTc(tcData.TcNo), [Validators.required]],
    TcId:  [tcData.TcId, [Validators.required]],
    TCFile:  [tcData.TCFile, [Validators.required]],
  });
}

  getTCList() {
    return this.apiService.getTCList().subscribe(response => {
      this.tcArrayList = response;
    });
  }

  deleteTC(id, index, name) {
    if (confirm('Are you sure to delete TC details of ' + name + ' ?')) {
      this.apiService.deleteTC(id).subscribe((response) => {
        this.toastr.success('TC deleted successfully');
        this.tcArrayList.splice( index, 1 );
      });
    }
  }

  formatTc(tc) {
    let tcNo: any;
        tcNo  = tc.split('-');
        tcNo = tcNo[0] + '/' + tcNo[1];
        return tcNo;
  }

  addformatTc(tc) {
    let tcNo: any;
        tcNo  = tc.split('/');
        tcNo = tcNo[0] + '-' + tcNo[1];
        return tcNo;
  }

 onSubmit() {
    this.submitted = true;
    let tcData: any;
    let type: any;
    if (this.tcForm.valid) {
      this.isLoaderShown = true;
      if (this.tcForm.controls['TcId'].value === 0) {
        type = 'add';
        let tcNo: any;
        tcNo  = this.tcForm.value.TcNo.split('/');
        tcNo = tcNo[0] + '-' + tcNo[1];
         tcData = {
          'TcId': 0,
          'StudentName': this.tcForm.value.StudentName,
          'StudentId': this.tcForm.value.StudentId,
          'TcNo': this.addformatTc(this.tcForm.value.TcNo),
          'TCFile': this.tcForm.value.TCFile
        };
      } else {
        type = 'edit';
         tcData = {
          'TcId': this.tcForm.value.TcId,
          'StudentName': this.tcForm.value.StudentName,
          'StudentId': this.tcForm.value.StudentId,
          'TcNumber': this.addformatTc(this.tcForm.value.TcNo),
          'TcFile': this.tcForm.value.TCFile
        };
      }
      return this.apiService.manageTC(tcData, type).subscribe(response => {
        this.isLoaderShown = false;
        this.modalRef.hide();
        this.getTCList();
        this.toastr.success(type === 'add' ? 'TC added successfully' : 'TC updated Successfully'  );
      });
    }
  }
}
