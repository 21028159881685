import { Component, OnInit , TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit {

  modalRef: BsModalRef;
  staffArrayList: any;
  subjectArrayList: any;
  staffForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  isDOBChanged = false;
  isDOAChanged = false;
  staffObj = {
    'SatffId': 0,
    'StaffName': '',
    'Designation': '',
    'DOB': '',
    'DateOfAppointment': '',
    'Qualification': '',
    'Trained': true,
    'PartTime': false,
    'SubjectId': 1
  };
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  openModal(template: TemplateRef<any>, staffDataObj) {
    this.submitted = false;
    this.isLoaderShown = false;
    this.initValidation(staffDataObj);
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  ngOnInit() {
    if (localStorage.getItem('admin')) {
      this.getStaffList();
    } else {
      this.router.navigate(['/login']);
    }
  }

initValidation(staffData) {
  this.staffForm = this.formBuilder.group({
    SatffId: [staffData.SatffId, [Validators.required]],
    StaffName:  [staffData.StaffName , [Validators.required]],
    Designation:  [staffData.Designation, [Validators.required]],
    DOB:  [staffData.DOB, []],
// tslint:disable-next-line: max-line-length
    DateOfAppointment:  [staffData.DateOfAppointment, []],
    Qualification:  [staffData.Qualification, [Validators.required]],
    Trained:  [staffData.Trained, [Validators.required]],
    PartTime:  [staffData.PartTime, [Validators.required]],
    SubjectId:  [staffData.SubjectId, [Validators.required]],
  });
}

  getStaffList() {

    return this.apiService.getStaffList().subscribe(response => {
      this.staffArrayList = response['Data'].Staffdetail;
      this.getSubjectList();
    });
  }

  getSubjectList() {
    return this.apiService.getSubjectList().subscribe(response => {
      this.subjectArrayList = response;
    });
  }

  deleteStaff(id, index, name) {
    if (confirm('Are you sure to delete Staff details of ' + name + ' ?')) {
      this.apiService.deleteStaff(id).subscribe((response) => {
        this.toastr.success('Staff deleted successfully');
        this.getStaffList();
      });
    }
  }

  convertDate(date) {
      let mm: any;
      let dd: any;
      const currentDate = new Date(date);
      dd = currentDate.getDate();
       mm = currentDate.getMonth() + 1;

      const yyyy = currentDate.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      return dd + '/' + mm + '/' + yyyy;
  }

 onSubmit() {
    this.submitted = true;
    if (this.staffForm.valid) {
       this.isLoaderShown = true;
      // this.staffForm.value['DOB'] = this.dateFormat(this.staffForm.value['DOB']);
      // this.staffForm.value['DateOfAppointment'] = this.dateFormat(this.staffForm.value['DateOfAppointment']);
      return this.apiService.manageStaff(this.staffForm.value).subscribe(response => {
        this.isLoaderShown = false;
        this.modalRef.hide();
        this.getStaffList();
        this.isDOBChanged = false;
        this.isDOAChanged = false;
        this.toastr.success(this.staffForm.value.SatffId === 0 ? 'Staff added successfully' : 'Staff updated Successfully'  );
      });
    }
  }
}
