export const classStrength = [
    {class: 'LKG',  Section: 'A to C', Enrolment: '107'},
    {class: 'UKG',  Section: 'A to E', Enrolment: '178'},
    {class: 'I',  Section: 'A to F', Enrolment: '225'},
    {class: 'II',  Section: 'A to E', Enrolment: '176'},
    {class: 'III',  Section: 'A to E', Enrolment: '170'},
    {class: 'IV',  Section: 'A to D', Enrolment: '157'},
    {class: 'V',  Section: 'A to D', Enrolment: '137'},
    {class: 'VI',  Section: 'A to D', Enrolment: '144'},
    {class: 'VII',  Section: 'A to D', Enrolment: '135'},
    {class: 'VIII',  Section: 'A to C', Enrolment: '112'},
    {class: 'IX',  Section: 'A to C', Enrolment: '105'},
    {class: 'X',  Section: 'A & B', Enrolment: '78'},
    {class: 'XI',  Section: 'A & B', Enrolment: '63'},
    {class: 'XII',  Section: 'A & B', Enrolment: '49'}

];
export const experience = '16';
export const staffs = '80';
export const students = '4300+';
export const galleryYears = ['2020','2019','2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010'];
