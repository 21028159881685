import { Component, OnInit } from '@angular/core';
import { APIService } from '../../services/app-service';
import { classStrength} from '../../../assets/files/strength';
@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['../about/about.component.css','./campus.component.css']
})
export class CampusComponent implements OnInit {
  oneAtATime: boolean = true;
  feeArrayList: any;
  salaryArrayList: any;
  staffArrayList: any;
  subjectArrayList: any;
  divisonList = classStrength;
  constructor(
    private  apiService:  APIService
  ) { }

  ngOnInit() {
    this.getFeeList();
    this.getSalaryList();
    this.getStaffList();
  }

  getStaffList() {
    return this.apiService.getStaffList().subscribe(response => {
      this.staffArrayList = response['Data'].Staffdetail;
      this.getSubjectList();
    });
  }

  getSubjectList() {
    return this.apiService.getSubjectList().subscribe(response => {
      this.subjectArrayList = response;
    });
  }

  getFeeList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'fee');
      this.feeArrayList = filterList;
    });
  }

  getSalaryList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'salary');
      this.salaryArrayList = filterList;
    });
  }
}
