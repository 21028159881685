import { Component, OnInit , TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { APIService } from '../../services/app-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-salary',
  templateUrl: './add-salary.component.html',
  styleUrls: ['./add-salary.component.css']
})
export class AddSalaryComponent implements OnInit {
 

  modalRef: BsModalRef;
  salaryArrayList: any;
  salaryForm: FormGroup;
  submitted = false;
  isLoaderShown = false;
  salaryObj = {
    'Name': '',
    'Address': '',
    'SMCId': 0,
    'Designation': 'salary',
    'Priority': 1
  };
  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private  apiService:  APIService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  openModal(template: TemplateRef<any>, salaryDataObj) {
    this.submitted = false;
    this.isLoaderShown = false;
    this.initValidation(salaryDataObj);
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
  }

  ngOnInit() {
    if (localStorage.getItem('admin')) {
      this.getSalaryList();
    } else {
      this.router.navigate(['/login']);
    }
  }


initValidation(salaryData) {
  this.salaryForm = this.formBuilder.group({
    Name: [salaryData.Name, [Validators.required]],
    Designation:  [salaryData.Designation , [Validators.required]],
    Priority:  [salaryData.Priority, [Validators.required]],
    SMCId:  [salaryData.SMCId, [Validators.required]],
    Address:  [salaryData.Address, [Validators.required]],
  });
}

getSalaryList() {
    let filterList: any;
    let list: any = [];
    return this.apiService.getSMCDetailsList().subscribe(response => {
      list = response;
      filterList = list.filter(
        filterData => filterData.Designation === 'salary');
      this.salaryArrayList = filterList;
    });
  }

  deleteSalary(id, index, name) {
    if (confirm('Are you sure to delete salary details of ' + name + ' ?')) {
      this.apiService.deleteSalary(id).subscribe((response) => {
        this.toastr.success('Salary deleted successfully');
        this.salaryArrayList.splice( index, 1 );
      });
    }
  }

 onSubmit() {
    this.submitted = true;
    if (this.salaryForm.valid) {
      this.isLoaderShown = true;

      return this.apiService.manageSalary(this.salaryForm.value).subscribe(response => {
        this.isLoaderShown = false;
        this.modalRef.hide();
        this.getSalaryList();
        this.toastr.success(this.salaryForm.value.SMCId === 0 ? 'Salary added successfully' : 'Salary updated Successfully'  );
      });
    }
  }
}
